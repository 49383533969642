'use client';

import { useEffect } from 'react';

import { useRUMRecording } from '@/components/hooks/rum';
import { useTDPageview } from '@/components/hooks/td';

export default function ClientConfig() {
  useRUMRecording();
  useTDPageview();
  useEffect(() => {
    window.onerror = function (message, url, line, column, error) {
      // eslint-disable-next-line no-console
      console.log(message, url, line, column, error);
    };
  }, []);
  return <></>;
}
